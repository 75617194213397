import Divider from '../Divider';
import ButtonPrimary from '../ButtonPrimary';
import SupportEmailLine from '../SupportEmailLine';
import { TURBINE_AUTH } from '../../../constants/urls';

const signInWithEmailLinkHtml = ({
  emailLink,
  linkText = 'Access Link',
  trackingSlug
  // orgSlug // /login${orgSlug ? `/${orgSlug}` : ''}
}) => `
  <p>
    This email provides you with secure access to your account.
  </p>

  <p>
    To access your account, click on the following link:
  </p>

  <div
    align="center"
    style="line-height:10px; padding-top:20px;padding-bottom:20px;"
  >
    ${ButtonPrimary({ text: linkText, href: emailLink })}
  </div>

  <p>
    P.S. This link only works once and <strong>expires in 10 minutes</strong>. If you need a new link, please click <a href="${TURBINE_AUTH}/pw-forgot?track=${trackingSlug}NewLink" rel="noopener" style="text-decoration: underline; color: #8e44ad;" target="_blank">here</a>.
  </p>


  ${SupportEmailLine('If you have received this email in error or have any questions, please reach out to us at')}

  ${Divider()}

  <p>
    "${linkText}" button not working?
  </p>

  <p>
    Copy and paste this link in your browser:
  </p>

  <p>
    ${emailLink}
  </p>


`;

export default signInWithEmailLinkHtml;
