import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  // isSignInWithEmailLink,
  verifyPasswordResetCode
  // checkActionCode,
  // applyActionCode,
  // sendPasswordResetEmail
} from '@firebase/auth';
import { auth } from '../../../firebaseConfig';
import { setAuthAction } from '../../../actions';
import { FORGOT_PW, LOGIN, RESET_PW } from '../../../constants/routes';
import {
  AUTH_ACTION_RESET_PW,
  AUTH_ACTION_RECOVER_EMAIL,
  AUTH_ACTION_VERIFY_EMAIL,
  AUTH_ACTION_SIGN_IN
} from '../../../constants/firebase';
import Loading from '../../Loading';
import { redirectTo } from '../../../services/auth';

const AuthAction = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Misc
  const searchParams = new URLSearchParams(window.location.search);
  const mode = searchParams.get('mode'); // Get the action to complete, ex: resetPassword
  const actionCode = searchParams.get('oobCode'); // Get the one-time code from the query parameter, ex: RXB2GcPscqRAV77upmNMhh9Oc33c5GiM4_10SdNjh0EAAAFxNF_ObQ
  const lang = searchParams.get('lang'); // (Optional) Get the language code if available, ex: en
  const continueUrl = searchParams.get('continueUrl'); // (Optional) Get the continue URL from the query parameter if available.

  const handleSignInWithEmailLink = useCallback(() => {
    console.log('AUTH: decoded', {
      mode,
      actionCode,
      lang,
      continueUrl
    });

    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
    }

    redirectTo({
      email,
      emailLink: window.location.href,
      actionCode
      // continueUrl
    });
  }, [actionCode, continueUrl, lang, mode]);

  const handleResetPassword = useCallback(() => {
    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode).then((email) => {
      dispatch(setAuthAction({
        actionCode,
        actionCodeChecked: true,
        email,
        lang,
        continueUrl
      }));

      // TODO: Show pw reset screen with the user's email, ask for new password.
      history.replace(RESET_PW);
    }).catch(() => { // code
      // Invalid or expired action code. Ask user to reset the password again.
      history.replace(FORGOT_PW);

      toast.error('Something went wrong. Please try to reset your password again.', { autoClose: false });
    });
  }, [actionCode, continueUrl, dispatch, history, lang]);

  const handleRecoverEmail = () => {
    // https://firebase.google.com/docs/auth/custom-email-handler
    console.log('handleRecoverEmail');

    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // var restoredEmail = null;
    // // Confirm the action code is valid.
    // checkActionCode(auth, actionCode).then((info) => {
    //   // Get the restored email address.
    //   restoredEmail = info?.data?.email; // ['data']['email']
    //
    //   // Revert to the old email.
    //   return applyActionCode(auth, actionCode);
    // }).then(() => {
    //   // Account email reverted to restoredEmail
    //
    //   // TODO: Display a confirmation message to the user.
    //
    //   // You might also want to give the user the option to reset their password
    //   // in case the account was compromised:
    //   sendPasswordResetEmail(auth, restoredEmail).then(() => {
    //     // Password reset confirmation sent. Ask user to check their email.
    //   }).catch((error) => {
    //     // Error encountered while sending password reset code.
    //   });
    // }).catch((error) => {
    //   // Invalid code.
    // });
  };

  const handleVerifyEmail = () => {
    // https://firebase.google.com/docs/auth/custom-email-handler
    console.log('handleVerifyEmail');
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    // applyActionCode(auth, actionCode).then((resp) => {
    //   // Email address has been verified.
    //
    //   // TODO: Display a confirmation message to the user.
    //   // You could also provide the user with a link back to the app.
    //
    //   // TODO: If a continue URL is available, display a button which on
    //   // click redirects the user back to the app via continueUrl with
    //   // additional state determined from that URL's parameters.
    // }).catch((error) => {
    //   // Code is invalid or expired. Ask the user to verify their email address
    //   // again.
    // });
  };

  const handleDefault = useCallback(() => {
    // const code = 'invalidMode'

    toast.error('Something went wrong. Try again.', { autoClose: false });

    history.replace(LOGIN);
  }, [history]);

  const parseAuthAction = useCallback(() => {
    if (actionCode && lang && continueUrl) {
      dispatch(setAuthAction({
        actionCode,
        actionCodeChecked: false,
        lang,
        continueUrl
      }));

      // if (isSignInWithEmailLink(auth, window.location.href)) {
      //   return handleSignInWithEmailLink({ actionCode, continueUrl, lang });
      // }

      switch (mode) {
        case AUTH_ACTION_SIGN_IN:
          return handleSignInWithEmailLink({ actionCode, continueUrl, lang });
        case AUTH_ACTION_RESET_PW:
          return handleResetPassword({ actionCode, continueUrl, lang });
        case AUTH_ACTION_RECOVER_EMAIL:
          return handleRecoverEmail({ actionCode, lang });
        case AUTH_ACTION_VERIFY_EMAIL:
          return handleVerifyEmail({ actionCode, continueUrl, lang });
        default:
          return handleDefault();
      }
    }

    return handleDefault();
  }, [actionCode, continueUrl, dispatch, handleDefault, handleResetPassword, handleSignInWithEmailLink, lang, mode]);

  useEffect(() => {
    document.getElementsByTagName('html')[0].className = 'authpage';

    parseAuthAction();

    return function cleanup() {
      document.getElementsByTagName('html')[0].className = '';
    };
  }, [parseAuthAction]);

  return (
    <Loading />
  );
};

export default AuthAction;
