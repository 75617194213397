import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import slugify from 'slugify';
import { DEFAULT_ORG_LOGO } from '@apprentage/constants';
import { newOrgWelcomeEmail } from '../../services/mailer';
import { createLocation } from '../../services/locations';
import { createOrg, fetchOrg } from '../../services/organizations';
import { fetchUserByEmail } from '../../services/user';
import { TURBINE_MARKETING_SITE, LOCAL_TURBINE_MARKETING_SITE } from '../../constants/urls';
import { getGeoLocation } from '../../services/ipApi';
import Container from '../Container';
import SelectMenu from '../ManageContent/SelectMenu';
import Success from './Success';
import Loading from '../Loading';

const OrgCreate = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const hasQuery = Boolean(window.location.search.length);
  const orgTypeOptions = [
    { value: '', label: 'Select one' },
    { value: 'workforce', label: 'Enterprise' },
    { value: 'community', label: 'Community' }
  ];
  const orgTypeDefault = hasQuery ? orgTypeOptions.find((oto) => oto.value === searchParams.get('orgType')) : null;
  const [loading, setLoading] = useState(false);
  const [orgCreated, setOrgCreated] = useState(false);
  const [orgType, setOrgType] = useState(searchParams.get('orgType') || '');

  const [orgName, setOrgName] = useState('');
  const [orgLogo, setOrgLogo] = useState('');
  const [orgSlug, setOrgSlug] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [showOrgSlugField, setShowOrgSlugField] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [shouldAllowAccountCreation, setShouldAllowAccountCreation] = useState(false);
  const [disableAccountCreation, setDisableAccountCreation] = useState(false);
  // Location
  const [addressPostalCode, setAddressPostalCode] = useState(null);
  const [addressCity, setAddressCity] = useState(null);
  const [addressState, setAddressState] = useState(null);
  const [addressCountry, setAddressCountry] = useState(null);
  // Misc
  const hideOrgType = Boolean(searchParams.get('orgType'));
  const enableWorkforceSuite = searchParams.get('enableWorkforceSuite') || null;

  const userName = useMemo(() => {
    let fullName = '';

    if (firstName) fullName += firstName;
    if (lastName) fullName += ` ${lastName}`;

    return fullName;
  }, [firstName, lastName]);

  const whitelistedDomains = useMemo(() => {
    return [
      TURBINE_MARKETING_SITE,
      `${TURBINE_MARKETING_SITE}/`,
      LOCAL_TURBINE_MARKETING_SITE,
      `${LOCAL_TURBINE_MARKETING_SITE}/`
    ];
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data) {
        try {
          const messageData = JSON.parse(e.data);

          if (messageData.action === 'orgCreate') {
            if (whitelistedDomains.includes(e.origin)) {
              console.log('orgCreate', messageData);

              setShouldAllowAccountCreation(true);
            } else {
              setDisableAccountCreation(true);
            }
          }
        } catch (error) {
          // nothing
        }
      }
    });
  }, [whitelistedDomains]);

  useEffect(() => {
    if (shouldAllowAccountCreation) {
      getGeoLocation().then((geoResponse) => {
        if (geoResponse?.zip) {
          setAddressPostalCode(geoResponse?.zip);
        }
        if (geoResponse?.city) {
          setAddressCity(geoResponse?.city);
        }
        if (geoResponse?.state) {
          setAddressState(geoResponse?.state);
        }
        if (geoResponse?.countryCode) {
          setAddressCountry(geoResponse?.countryCode);
        }
      });
    }
  }, [shouldAllowAccountCreation]);

  const createOrganization = () => {
    setLoading(true);

    const orgDataToSave = {
      type: orgType,
      name: orgName,
      slug: orgSlug,
      integration: {
        stripe: {
          products: { // Enterprise (workforce) pricing
            live: [
              'prod_IxzzoIZoy9QUvN'
            ],
            test: [
              'prod_Iy0UPJXaS8Yitx'
            ]
          }
        }
      }
    };

    if (orgType === 'community') {
      // TODO added separate pricing
      orgDataToSave.restrictRegistration = false;

      if (enableWorkforceSuite) {
        orgDataToSave.enableWorkforceSuite = true;
      }
    }

    // Enterprise
    if (orgType === 'workforce') {
      orgDataToSave.enableProjects = true;
      orgDataToSave.restrictRegistration = true;
      // orgDataToSave.integrateMicrosoftTeams = true;
      // TODO added separate pricing
    }

    // 1. Create Org
    createOrg(orgDataToSave).then((response) => {
      const orgId = response?.sys?.id || null;

      const locationDataToSave = {
        name: orgName,
        orgId,
        // addressLine1,
        // addressLine2,
        ...(addressPostalCode ? { addressPostalCode } : {}),
        ...(addressCity ? { addressCity } : {}),
        ...(addressState ? { addressState } : {}),
        ...(addressCountry ? { addressCountry } : {})
      };

      // 2. Create Location
      createLocation(locationDataToSave).then((newLocation) => {
        // 3. Email user to register as an Owner
        setOrgLogo(DEFAULT_ORG_LOGO);
        setOrgCreated(true);
        newOrgWelcomeEmail({
          email: userEmail,
          name: userName,
          phone: userPhone,
          orgName,
          orgSlug,
          locationId: newLocation?.sys?.id // TODO flatten after migrating to Supabase
        });
      });
    }).catch((error) => {
      toast.error('Something went wrong, please try again.', { autoClose: false });
      console.error(error);
      // TODO email Turbine Admins
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!orgType) {
      toast.error('Organization Type is required');
      return false;
    }

    if (showOrgSlugField && !orgSlug) {
      toast.error('Organization slug is required.');
      return false;
    }

    // Check if email is unique
    fetchUserByEmail(userEmail).then((response) => {
      // No user found with email provided
      if (!response) {
        // Check if Org Slug is unique
        fetchOrg({ slug: orgSlug, select: ['fields.slug'] }).then((orgs) => {
          // No organizations found with orgSlug provided
          if (!orgs) {
            createOrganization();
          } else {
            setShowOrgSlugField(true);
            toast.error('This URL is taken, choose a different URL.', { autoClose: false });

            return false;
          }
        });
      } else {
        toast.error('Email already registered. Please use a different email.', { autoClose: false });

        return false;
      }
    });
  };

  if (disableAccountCreation) {
    return (
      <Container
        className="org-create"
        bodyClassName='org-create-container'
        showLegal={false}
        showPoweredBy={false}
      >
        <div>
          <p>
            Unauthorized access.
          </p>
        </div>
      </Container>
    );
  }

  if (!shouldAllowAccountCreation) {
    return (
      <Loading className="position-fixed" />
    );
  }

  if (orgCreated) {
    return (
      <Container
        className="org-create"
        bodyClassName='org-create-container'
        orgLogo={orgLogo}
        showLegal={false}
        showPoweredBy={false}
      >
        <Success
          userEmail={userEmail}
          orgName={orgName}
        />
      </Container>
    );
  }

  return (
    <Container
      className="org-create"
      bodyClassName='org-create-container'
      showPoweredBy={false}
      showLegal={false}
      showLogo={false}
    >
      <form
        onSubmit={onSubmit}
      >
        <div className="pb-2 mb-2">
          <label
            htmlFor="email"
            className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
          >
            <span className='d-flex align-items-center'>
              <span>Organization Name</span>
              <span className='text-danger ml-1'>*</span>
            </span>
          </label>

          <input
            type="text"
            id="orgName"
            name="orgName"
            className="form-control"
            defaultValue={orgName}
            required
            autoFocus
            onChange={(e) => {
              const { value: newOrgName } = e.currentTarget;

              const slug = slugify(newOrgName, {
                lower: true,
                strict: true
              });

              setOrgSlug(slug);
              setOrgName(newOrgName);
            }}
          />
        </div>

        <div
          className={`p-2 alert-info mb-2 rounded border ${showOrgSlugField ? 'd-show' : 'd-none'}`}
        >
          <label
            htmlFor="email"
            className="w-100 text-left mb-1 font-weight-bold"
          >
            <span className='d-flex align-items-center'>
              <span>Organization URL</span>
              <span className='text-danger ml-1'>*</span>
            </span>
          </label>

          <input
            type="text"
            id="orgSlug"
            name="orgSlug"
            className="form-control"
            value={orgSlug}
            onChange={(e) => {
              const { value: newOrgSlug } = e.currentTarget;

              const slug = slugify(newOrgSlug, {
                lower: true,
                remove: /[*+~.()'"!:@]/g
              });

              setOrgSlug(slug);
            }}
          />

          <div className='mt-2 text-muted text-left small'>
            <span className='d-flex align-items-center'>
              <span className='d-none d-sm-block'>https://</span>
              <span>admin.turbine.is/login/<strong>{orgSlug}</strong></span>
            </span>

          </div>
        </div>

        <div className={`pb-2 mb-2 ${hideOrgType ? 'd-none' : 'd-block'}`}>
          <label
            htmlFor="email"
            className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
          >
            Organization Type
          </label>

          <SelectMenu
            id="orgType"
            name="orgType"
            autoComplete="new-orgType"
            options={orgTypeOptions}
            defaultValue={orgTypeDefault}
            onChange={({ orgType: newOrgType }) => {
              setOrgType(newOrgType);
            }}
          />
        </div>

        <div className="mb-3 d-flex">
          <div className="mr-3 flex-fill">
            <label
              htmlFor="firstName"
              className="text-gray-dark mb-1 font-weight-bold w-100 text-left"
            >
              <span className='d-flex align-items-center'>
                <span>First Name</span>
                <span className='text-danger ml-1'>*</span>
              </span>
            </label>

            <input
              type="text"
              name="firstName"
              className="form-control"
              autoComplete="new-firstName"
              value={firstName}
              required
              onChange={(e) => {
                const { value } = e.currentTarget;

                setFirstName(value);
              }}
            />
          </div>
          <div className="flex-fill">
            <label
              htmlFor="lastName"
              className="text-gray-dark mb-1 font-weight-bold w-100 text-left"
            >
              <span className='d-flex align-items-center'>
                <span>Last Name</span>
                <span className='text-danger ml-1'>*</span>
              </span>
            </label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              autoComplete="new-lastName"
              value={lastName}
              required
              onChange={(e) => {
                const { value } = e.currentTarget;

                setLastName(value);
              }}
            />
          </div>
        </div>

        <div className="pb-2 mb-2">
          <label
            htmlFor="email"
            className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
          >
            <span className='d-flex align-items-center'>
              <span>Phone</span>
              <span className='text-danger ml-1'>*</span>
            </span>
          </label>

          <input
            type="text"
            id="userPhone"
            name="userPhone"
            autoComplete="new-phone"
            className="form-control"
            placeholder="412-555-1212"
            required
            value={userPhone}
            onChange={(e) => {
              const { value: newUserPhone } = e.currentTarget;

              setUserPhone(newUserPhone);
            }}
          />
        </div>

        <div className="pb-2 mb-2">
          <label
            htmlFor="email"
            className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
          >
            <span className='d-flex align-items-center'>
              <span>Work Email</span>
              <span className='text-danger ml-1'>*</span>
            </span>
          </label>

          <input
            type="email"
            id="email"
            name="email"
            autoComplete="new-email"
            className="form-control"
            required
            value={userEmail}
            onChange={(e) => {
              const { value } = e.currentTarget;

              setUserEmail(value);
            }}
          />
        </div>

        <button
          disabled={loading}
          className="btn btn-md btn-primary mt-2"
          type="submit"
          title="Create Organization"
        >
          {loading ? 'Creating Organization...' : 'Create Organization'}
        </button>
      </form>
    </Container>
  );
};

export default OrgCreate;
